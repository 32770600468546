import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { Offline, Online, Detector } from "react-detect-offline";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import { reducer, store } from "./store/reducer";
import config from "./config";

import { AuthProvider } from "./Services/auth";
import AuthGuard from "./App/components/AuthGuard";
//import webscreen from './pages/screenweb/webscreen.js';
// import NoInternetConnection from './shared/components/NoInternetConnection';

// import offlineIco from './assets/images/brand/offline-logo.ico';

const storeReducer = createStore(reducer);
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// function getFaviconEl() {
//     return document.getElementById("favicon");
// }

// const onlineFav = () => {
//     console.log("working")
//     const favicon = getFaviconEl(); // Accessing favicon element
//     favicon.href = "https://www.google.com/favicon.ico";
// }

// const offlineFav = () => {
//     console.log("working")
//     const favicon = getFaviconEl(); // Accessing favicon element
//     favicon.href = "https://stackoverflow.com/favicon.ico";
// }

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <AuthProvider>
        {/* <AuthGuard> */}
        <App />
        {/* </AuthGuard> */}
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
