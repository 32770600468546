import {combineReducers} from "redux";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import FavoritesReducer from "../../pages/Favorite/redux/favorites-reducer";
import ItemsReducer from "../../pages/Items/redux/items-reducer";

const rootReducer = combineReducers({
  favorites: persistReducer({key: "favorites", storage: sessionStorage}, FavoritesReducer.reducer),
  items: persistReducer({key: "items", storage: sessionStorage}, ItemsReducer.reducer),

});

export default rootReducer;
