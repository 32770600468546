import { createSlice, current } from "@reduxjs/toolkit";
//import produce from 'immer';
import React from "react";

const initialState = {
  selectItem: "",
};

const ItemsReducer = createSlice({
  name: "ItemsReducer",
  initialState: initialState,
  reducers: {
    setItemData: (state, { payload }) => {
      return {
        ...state,
        selectItem: payload,
      };
    },
    clearItemsData: (state) => {
      return {
        ...state,
        selectItem: "",
      };
    },
  },
});

export default ItemsReducer;
