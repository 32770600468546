import React from 'react';


const SignUp1 = React.lazy(() => import('./pages/Authentication/SignUp/SignUp1'));
const SignIn = React.lazy(() => import('./pages/Authentication/SignIn/SignIn'));
const Console = React.lazy(() => import("./pages/Console"));
const Menu = React.lazy(() => import("./pages/Menu"));
const Items = React.lazy(() => import("./pages/Items"));
const Favorites = React.lazy(() => import("./pages/Favorite"));
const Description = React.lazy(() => import("./pages/Description"));
const WebCard = React.lazy(() => import('./pages/ScreenWeb/WebScreen.js'));
const ParkingSpaces = React.lazy(() => import('./pages/ParkingSpaces/ParkingSpaces.js'));
const ClientMultipleParkingValidation = React.lazy(() => import('./pages/Authentication/SignIn/ClientMultipleParkingValidation'));
const ClientSingleParkingValidation = React.lazy(() => import('./pages/Authentication/SignIn/ClientSingleParkingValidation'));
const ValidateMessage = React.lazy(() => import('./pages/Authentication/SignIn/ValidateMessage')); //
const ClientParkingValertTariffValidation = React.lazy(() => import('./pages/Authentication/SignIn/ClientParkingValertTariffValidation'));
const LLValidationHolder = React.lazy(() => import('./pages/LLParking/LLValidation/LLValidationHolder'));
const LLWhiteListingValidation = React.lazy(() => import('./pages/LLParking/LLValidation/LLWhiteListingValidation'));
const LLVisitorParkingFuture = React.lazy(() => import('./pages/LLParking/LLValidation/LLVisitorParkingFuture'));
const LLVisitorParkingCurrent = React.lazy(() => import('./pages/LLParking/LLValidation/LLVisitorParkingCurrent'));
const landlordDashBoard = React.lazy(() => import('./pages/Dashboard/LandlordDashboard'));
const LandLordSessionReportHolder = React.lazy(() => import('./pages/Report/LandLordSessionReportHolder'));
const ClientDashBoard = React.lazy(() => import('./pages/Dashboard/ClientDashboard'));
const ClientHolder = React.lazy(() => import('./pages/Dashboard/ClientHolder'));
const ClientSessionReportHolder = React.lazy(() => import('./pages/Report/ClientSessionReportHolder'));

const route = [
    // { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: SignIn },
    { path: "/", exact: true, name: "Console", component: Console },
    { path: "/menu", exact: true, name: "Menu", component: Menu },
    { path: "/menu/items", exact: true, name: "Items", component: Items },
    { path: "/favorites", exact: true, name: "Favorites", component: Favorites },
    { path: "/item/description", exact: true, name: "Description", component: Description },
    { path: '/web-card', exact: true, name: 'Web Card', component: WebCard },
    { path: '/parking-spaces', exact: true, name: 'Parking Spaces', component: ParkingSpaces },

    { path: '/client-multiple-parking-validation/:id', exact: true, name: 'Signin 1', component: ClientMultipleParkingValidation },
    { path: '/client-single-parking-validation/:id', exact: true, name: 'Signin 1', component: ClientSingleParkingValidation },
    { path: '/validation-message', exact: true, name: 'Signin 1', component: ValidateMessage },
    { path: '/validate-vrn', exact: true, name: 'Signin 1', component: ClientParkingValertTariffValidation },
    { path: '/ll-validate', exact: true, name: 'Signin 1', component: LLValidationHolder },
    { path: '/ll-whitelist-validate', exact: true, name: 'Signin 1', component: LLWhiteListingValidation },
    { path: '/ll-visitor-parking-future', exact: true, name: 'Signin 1', component: LLVisitorParkingFuture },
    { path: '/ll-visitor-parking-current', exact: true, name: 'Signin 1', component: LLVisitorParkingCurrent },
    { path: '/client-single-parking-validation', exact: true, name: 'Signin 1', component: ClientSingleParkingValidation },
    { path: '/client-single-parking-validation', exact: true, name: 'Signin 1', component: ClientSingleParkingValidation },
    { path: '/landlord-dashboard', exact: true, name: 'Signin 1', component: landlordDashBoard },
    { path: '/client-dashboard/:id', exact: true, name: 'Signin 1', component: ClientDashBoard },
    { path: '/clients', exact: true, name: 'Signin 1', component: ClientHolder },
    { path: '/ll-session-report-dashboard', exact: true, name: 'Signin 1', component: LandLordSessionReportHolder },
    { path: '/client-session-report-dashboard', exact: true, name: 'Signin 1', component: ClientSessionReportHolder }

];

export default route;