import { createSlice, current } from "@reduxjs/toolkit";
//import produce from 'immer';
import React from "react";

const initialState = {
  favoritesList: [],
  favoriteSpecialList: [],
};

const FavoritesReducer = createSlice({
  name: "FavoritesReducer",
  initialState: initialState,
  reducers: {
    addFavoritesList: (state, { payload }) => {
      console.log({ payload });
      return {
        ...state,
        favoritesList: [...state.favoritesList, payload],
      };
    },
    addSpecialFavoritesList: (state, { payload }) => {
      return {
        ...state,
        favoriteSpecialList: [...state.favoriteSpecialList, payload],
      };
    },
    setFavoritesList: (state, { payload }) => {
      return {
        ...state,
        favoritesList: payload,
      };
    },
    clearFavoritesList: (state) => {
      return {
        ...state,
        favoritesList: [],
      };
    },
  },
});

export default FavoritesReducer;
