import { createContext, useContext, useState } from "react";
import { NavLink, useHistory } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const history = useHistory();

    const login = (user, data) => {
        console.log("USER DATA :: ", data);
        if (localStorage.getItem("isRememberMe") === "true") {
            console.log("REMBER ME CHECKED");
            localStorage.setItem("token", data.token);
            localStorage.setItem("user", user);
            localStorage.setItem("userId", data.userID);
            localStorage.setItem("userType", data.userType);
        } else {
            console.log("REMBER ME UNCHECKED");
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("user", user);
            sessionStorage.setItem("userId", data.userID);
            sessionStorage.setItem("userType", data.userType);
        }
        setUser(user);
    }

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("isRememberMe");
        localStorage.removeItem("userId");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        setUser(null);
        history.push('/auth/signin');
    }


    return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}

export const getStorageItem = (itemName) => {
    if (localStorage.getItem("isRememberMe") === "true") {
        return localStorage.getItem(itemName);
    } else {
        return sessionStorage.getItem(itemName);
    }
}